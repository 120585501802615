import React, { useEffect, useState } from 'react';
import {
  FeaturesFormSchema,
  FeaturesFormType,
  VinlyCoverLinerFeaturesFormSchema,
} from './FeaturesFormSchema';
import OrderForm, { GenericFieldValidationType } from '../../../OrderForm';
import FeaturesPlaceholder from '../../../../../../sections/orders/FeaturesPlaceholder/FeaturesPlaceholder';
import {
  featureOrderState,
  GetFeatureValuesOrDefault,
  OrderContext,
  OrderState,
} from '../../../../../../context/OrderContext';
import './FeaturesForm.scss';
import { CoverFeature, featureValuesType } from '../Feature/CoverFeature';
import { BaseDeckFormType } from '../Feature/FeatureSchema';
import { FeatureType, OrderStatus, OrderType } from 'models';
import { LinerFeature } from '../Feature/LinerFeature';
import {
  ObstructionTypeArray,
  SpecFormPropTypes,
  VinylCoveredStepsInfo,
} from '../../Spec/SpecFormSchema';
import { SameAsFloor, YES } from '../Feature/FeatureConstants';
import { OrderActionType } from 'context/OrderReducer';
import { getLathamSpec } from 'components/orders/helpers/getLathamSpec';
import { strict } from 'assert';
import { string } from 'yup';
import { LabeledResources } from 'sections/orders/Orders';

interface FeaturesFormProps {
  data: FeaturesFormType;
  hasEditAccess: boolean;
}

export interface FeatureFormFieldValidationStatus {
  id: boolean;
  featureName: boolean;
  anchorTypeOptions: boolean;
  cableAssembly: boolean;
  isRemovable: boolean;
  stepFastener: boolean;
  stepComment: boolean;
  isCovered: boolean;
  stepMaterialOption: boolean;
  stepStripe: boolean;
  stripeWidth: boolean;
  differentTreads: boolean;
  riserMaterial: boolean;
  stepMaterial: boolean;
  notes: boolean;
}

export interface FeaturesFormFieldsValidationStatus {
  features: FeatureFormFieldValidationStatus[];
}
interface PageProps {
  props: FeaturesFormProps;
  orderContext: {
    state: OrderState;
    dispatch: React.Dispatch<OrderActionType>;
  };
}
/**
 * @todo dynamic features
 * @param props
 * @constructor
 */
const FeaturesForm = (props: FeaturesFormProps) => {
  const { state, dispatch } = React.useContext(OrderContext);
  const key = state?.features?.features?.map((feat) => feat.id)?.sort() ?? '';
  return (
    <FeaturesPage
      props={props}
      orderContext={{ state, dispatch }}
      key={key.toString()}
    />
  );
};
function FeaturesPage(pageProps: PageProps) {
  const props = pageProps.props;
  const { state, dispatch } = pageProps.orderContext;
  const featuresScanData = state.featuresData;
  const disabled = state?.order?.archived || !props?.hasEditAccess;
  const isReadyOrderForVinlyFeature =
    state?.order?.status === OrderStatus.ReadyForOrder &&
    state?.order?.type === OrderType.Liner;
  const isAfterQuote =
    state.order?.status === OrderStatus.QuotedOptions ||
    state.order?.status === OrderStatus.ReadyForOrder;

  useEffect(() => {
    // if no features are tagged then the yup validation will always be successfull
    // to prevent this from happening we require that isViewed is true and update that value once this component mounts
    updateOrderState();
  }, []);

  /**
   * All fields are "valid", until we get an error!
   */
  const InitialFeaturesFormValidationStatus =
    (): FeaturesFormFieldsValidationStatus => {
      if (featuresScanData.length) {
        const features = featuresScanData.map((el) => {
          return {
            id: true,
            featureName: true,
            anchorTypeOptions: true,
            cableAssembly: true,
            isRemovable: true,
            stepFastener: true,
            stepComment: true,
            isCovered: true,
            stepMaterialOption: true,
            stepMaterial: true,
            stepStripe: true,
            stripeWidth: true,
            differentTreads: true,
            riserMaterial: true,
            notes: true,
          } as FeatureFormFieldValidationStatus;
        });
        return { features: features };
      }
      return { features: [] };
    };

  // Validation state
  const [validFields, setValidFields] =
    useState<FeaturesFormFieldsValidationStatus>(
      InitialFeaturesFormValidationStatus()
    );
  const [features, setFeatures] = useState<BaseDeckFormType[]>(
    GetFeatureValuesOrDefault(state)
  );

  const getCurrentFeatureNote = (feature: BaseDeckFormType) => {
    let notes = '';
    if (feature && feature.notes && feature.notes.length > 0) {
      const noteList = feature.notes.split('. ');
      noteList.forEach((note: string, index) => {
        if (note.length > 0) {
          notes += note;
          if (index != noteList.length - 1) {
            notes += '. ';
          }
        }
      });
    }
    return notes;
  };

  const getFeatureAttributes = (lathamSpec?: SpecFormPropTypes) => {
    if (!lathamSpec) return [];
    if (lathamSpec.coverFeatureAttributes) {
      lathamSpec.coverFeatureAttributes.obstructionTypeArray?.forEach(
        (info: ObstructionTypeArray, index) => {
          const currentFeature = features.filter(
            (feature) => feature.featureName === info.obstructionLabel
          );
          if (currentFeature.length > 0) {
            info.obstructionComments = getCurrentFeatureNote(currentFeature[0]);
          }
        }
      );
      return lathamSpec.coverFeatureAttributes;
    }
    return [];
  };

  const getVinylCoveredStepsInfoSpec = (lathamSpec?: SpecFormPropTypes) => {
    if (!lathamSpec) return [];
    const vinlyCoverFeatures = features.filter(
      (feature) => feature.featureType === FeatureType.VinylFeature
    );
    lathamSpec.vinylCoveredSteps?.vos?.forEach(
      (stepsInfo: VinylCoveredStepsInfo, index) => {
        stepsInfo.texturedStepFeatureSId =
          vinlyCoverFeatures[index]?.stepMaterialOption === 'Textured'
            ? 'F820_1'
            : '';
        stepsInfo.stepMaterial =
          vinlyCoverFeatures[index]?.stepMaterialOption === SameAsFloor
            ? state.spec.linerMaterialAttributes.materialFloor
            : vinlyCoverFeatures[index]?.stepMaterial;
        stepsInfo.stepMaterialOption =
          vinlyCoverFeatures[index]?.stepMaterialOption ?? '';
        stepsInfo.stepComment =
          vinlyCoverFeatures[index]?.stepComment?.replace(
            'Vinyl Feature',
            'VF'
          ) ?? '';
        stepsInfo.stepComment || vinlyCoverFeatures[index]?.notes;
        stepsInfo.stepFastener = vinlyCoverFeatures[index]?.stepFastener ?? '';
        stepsInfo.whiteStripeTread =
          vinlyCoverFeatures[index]?.stepStripe === YES;
        stepsInfo.colorStripeWidth = Number(
          vinlyCoverFeatures[index]?.stripeWidth
        );
      }
    );
    return lathamSpec.vinylCoveredSteps;
  };
  const lathamSpec = getLathamSpec(state);
  const specVals = {
    ...lathamSpec,
    vinylCoveredSteps: getVinylCoveredStepsInfoSpec(lathamSpec),
    coverFeatureAttributes: getFeatureAttributes(state.spec),
  };

  const updateOrderState = () => {
    dispatch({
      type: 'updateFeatures',
      payload: { features: features, isViewed: true } as featureOrderState,
    });
    dispatch({
      type: 'updateSpec',
      payload: specVals as SpecFormPropTypes,
    });
  };

  const setFeature = (feature: BaseDeckFormType) => {
    setFeatures((featuresToSet: BaseDeckFormType[]) =>
      featuresToSet.length == 0
        ? [feature]
        : featuresToSet.map((oldFeature) =>
            feature.id == oldFeature.id ? feature : oldFeature
          )
    );
  };

  const getFieldNotes = (feature: LabeledResources, index: number) => {
    let appNote = '';
    const newFeature = features[index];
    if (newFeature && newFeature.notes && newFeature.notes.length > 0) {
      const noteList = newFeature.notes.split('. ');
      if (noteList.length == 2) {
        appNote = noteList[0];
      } else {
        appNote = noteList[0];
      }
    }
    return appNote;
  };

  const getFeatureNotes = (feature: LabeledResources, index: number) => {
    let portalNote = '';
    const newFeature = features[index];

    if (newFeature && newFeature.notes && newFeature.notes.length > 0) {
      const noteList = newFeature.notes.split('. ');
      if (noteList.length == 2) {
        portalNote = noteList[1];
      }
    }
    return portalNote;
  };

  useEffect(() => {
    if (!features.length) {
      dispatch({
        type: 'updateFeatures',
        payload: { isValid: true } as featureOrderState,
      });
    }
  }, [features]);

  return (
    <div className="features-form">
      <div className="features-form__title">Features</div>
      {featuresScanData.length && features.length ? (
        <OrderForm
          onFieldsInvalid={updateOrderState}
          onFieldsValid={updateOrderState}
          values={{ features: features } as unknown as FeaturesFormType}
          schema={
            !isAfterQuote
              ? FeaturesFormSchema
              : VinlyCoverLinerFeaturesFormSchema
          }
          setValidFields={
            setValidFields as React.Dispatch<
              React.SetStateAction<GenericFieldValidationType>
            >
          }
          initialValidationState={InitialFeaturesFormValidationStatus()}
        >
          {featuresScanData.map((feature, i) => (
            <span key={i}>
              {state.project?.type === OrderType.Cover ? (
                <CoverFeature
                  name={feature.featureName}
                  key={`feature-form-${i}`}
                  title={feature.label}
                  type={feature.type}
                  notes={feature.notes}
                  feature={features[i] as featureValuesType}
                  measurement={feature?.measurement}
                  project={state?.project}
                  validFields={validFields.features[i]}
                  setFeature={(feature) => setFeature(feature)}
                  fieldNotes={getFieldNotes(feature, i)}
                  featureNotes={getFeatureNotes(feature, i)}
                  images={feature.images}
                  archived={disabled}
                />
              ) : (
                <LinerFeature
                  name={feature.featureName}
                  key={`feature-form-${i}`}
                  title={feature.label}
                  type={feature.type}
                  feature={features[i] as featureValuesType}
                  measurement={feature?.measurement}
                  project={state?.project}
                  linerMaterials={state?.linerMaterials}
                  validFields={validFields.features[i]}
                  setFeature={(feature) => setFeature(feature)}
                  notes={feature.notes}
                  fieldNotes={getFieldNotes(feature, i)}
                  featureNotes={getFeatureNotes(feature, i)}
                  moldedStepFace={feature.moldedStepFace}
                  images={feature.images}
                  archived={disabled}
                  brand={state.product.brand}
                  isAfterQuote={isAfterQuote}
                  isReadyOrderForVinlyFeature={isReadyOrderForVinlyFeature}
                />
              )}
              {i < featuresScanData.length - 1 && (
                <hr className="features-form__hr" />
              )}
            </span>
          ))}
        </OrderForm>
      ) : (
        <FeaturesPlaceholder />
      )}
    </div>
  );
}

export default FeaturesForm;
